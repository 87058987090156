
* {
    padding: 0;
    box-sizing: border-box;
    margin: 0;
  }
  
  .header {
    box-shadow: 0px 0px 10px rgba(120, 130, 140, 0.13);
    height: 60px;
  }
  
  .toggle-btn {
    height: 2px;
    /* Adjust height as needed */
    width: 20px;
    /* Adjust width as needed */
    background-color: red;
    margin: 5px;
  }
  
  .sidebar {
    box-shadow: 0px 0px 10px rgba(120, 130, 140, 0.13);
  }
  
  /* .list-unstyled li {
    font-size: 20px;
    font-weight: bold;
    padding: 10px;
    color: #9068be;
  } */
  
  .sidebarlist:hover {
    background-color: #F9F7FB;
    transition: 0.3s;
    box-shadow: 0px 0px 10px rgba(0, 8, 15, 0.13);
  }
  a {
   text-decoration: none;
   font-weight: 400 !important;
   color: black !important;
  }
  
  
  /* -------FORM CSS-------------------------------------------------------------------- */
  