/* src/Invoice.css */
.invoice-container {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.headerbill {
    padding: 10px;
}

.header h1 {
    margin: 0;
}

.invoice-title {
    color: white;
    background-color: #ffcd00;
}

.invoice-details,
.payment-info,
.terms-conditions {
    margin-bottom: 20px;
}

.table thead th {
    background-color: #ffd54f;
    color: white;
}

.total-table {
    margin-top: 20px;
}

.authorized-sign {
    text-align: right;
    margin-top: 40px;
}

.footer {
    text-align: center;
    background-color: #ffcd00;
    color: white;
    padding: 10px;
    margin-top: 20px;
}